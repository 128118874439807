import React from 'react'
import { Link } from 'gatsby'

import Image from './Image'

import './ItemList.css'

const ItemList = ({ list, subtitle }) => (
  <div className="item-list third flex">
    {list.map((item, index) => (
      <Link to={item.link} className="item" key={index}>
        <div className="image">
          <Image src={item.featuredImage} alt={item.title} />
        </div>
        <div className="dets">
          <h3>{item.title}</h3>
          <h5>{subtitle}</h5>
        </div>
      </Link>
    ))}
  </div>
)

export default ItemList
