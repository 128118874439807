import React from 'react'
import { graphql, Link } from 'gatsby'

import OpeningSlider from '../components/OpeningSlider'
import Image from '../components/Image'
import ItemList from '../components/ItemList'
import InstagramFeed from '../components/InstagramFeed'
import Layout from '../components/Layout'
import './HomePage.css'

// Export Template for use in CMS preview
export const HomePageTemplate = ({
  title,
  sectionHero,
  sectionIntro,
  sectionAbout,
  postCategories = []
}) => (
  <div className="home-page">
    <OpeningSlider
      title={title}
      slider={sectionHero.slider}
      link={sectionHero.link}
    />

    {/* intro section */}

    {!!sectionIntro.content && (
      <section className="intro white">
        <div className="thin center">
          <Image src="/images/logo-full.svg" alt="Mi Design Studio" />
          <h3>{sectionIntro.content}</h3>
        </div>
      </section>
    )}

    {/* posts section */}

    <section className="yellow">
      <div className="thin center">
        <h2>What we do</h2>
      </div>

      <div className="wide projects">
        {!!postCategories.length && (
          <ItemList list={postCategories} subtitle="View Category" />
        )}
      </div>

      <Link to="/projects" className="button">
        View all
      </Link>
    </section>

    {/* about section */}

    {!!sectionAbout.featuredImage && (
      <section className="about black relative">
        <div className="wide half flex">
          <div className="item">
            <Image
              src={sectionAbout.featuredImage}
              alt="About Mi Design Studio"
            />
          </div>
          <div className="item">
            <h3>{sectionAbout.title}</h3>
            <p>{sectionAbout.content}</p>

            <Link to="/about" className="link">
              Explore more
            </Link>
          </div>
        </div>
        <div className="accent absolute" />
      </section>
    )}
  </div>
  
)

// Export Default HomePage for front-end
const HomePage = ({ data: { page, postCategories } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <HomePageTemplate
      {...page}
      {...page.frontmatter}
      postCategories={postCategories.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
      body={page.html}
    />
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        sectionHero {
          slider {
            image
          }
          link
        }
        sectionIntro {
          content
        }
        sectionAbout {
          featuredImage
          title
          content
        }
      }
    }
    postCategories: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "postCategories" } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            link
            featuredImage
          }
        }
      }
    }
  }
`
