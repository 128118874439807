import React from 'react'
import { Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// import Image from './Image'
import './OpeningSlider.css'
import './PageHeader.css'

export default ({ slider, title, link }) => {
  const params = {
    infinite: true,
    slidesToShow: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 3500,
    centerPadding: 0,
    arrows: false
  }

  return (
    <div className="hero opener relative">
      <div className="absolute">
        <div className="wide">
          <h1>{title}</h1>
          {!!link && (
            <Link to={link} className="link">
              Enquire now
            </Link>
          )}
        </div>
      </div>
      <Slider {...params}>
        {slider.map((item, index) => (
          <img
            className="BackgroundImage"
            src={item.image}
            alt={'image-' + index}
            key={`item${index}`}
          />
        ))}
      </Slider>
    </div>
  )
}
